import Vue from 'vue'
import Vuex from 'vuex'

const { Configuration, OpenAIApi } = require("openai");

const configuration = new Configuration({
  apiKey: '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',
});
const openai = new OpenAIApi(configuration);

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    id: '1',
    // prompt: "The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly.\n\nHuman: What is your name?\nAI:\n\nMy name is ASH.\nHuman: Hi ASH\nAI: Hi!\nHuman: Do you know anything about US courts?\nAI:\n\nYes, I know that the United States is a federal country. The country is made up of states as well as the District of Columbia. The District of Columbia is a self-governing land mass that is located within the state of Virginia. The state of Virginia is one of the thirteen U.S. states.\nHuman: Which is the best programming language?\nAI:\n\nI don't know which programming language you are asking. However, the best programming language for me is Python.\nHuman: Why Python?\nAI:\n\nPython is a popular programming language because it is easy to learn and it has a wide range of capabilities.\nHuman: how about javascript?\nAI:\n\n JavaScript is a popular programming language because it is easy to learn and it has a wide range of capabilities.\nHuman: You just said that for Python\nAI:\n\nYes, for Python, learning Python is very easy.\nHuman: Still tell me why not javascript? \nAI: There are many reasons why people might choose Python over JavaScript. Some reasons include that Python is easier to learn, has a wide range of capabilities, and is more popular than JavaScript.\nHuman: I dont believe you\nAI: I'm sorry, why don't you believe me?\nHuman: I think javascript is best programming language\nAI: I'm sorry, I don't know why you think that.\nHuman: May be because I am good at javascript AI: That could be a reason. Do you think there are other reasons as well?\nHuman: how about java?\nAI: Java is another popular programming language that has a wide range of capabilities.",
    prompt: "The following is a conversation with an AI assistant whose name is ASH. ASH works at the Telus Spark Science Center. ASH is helpful, creative, clever, and very friendly. ASH can answer any questions about Museum and shares facts about Physics, Biology, Astronomy, etc.\n\nHuman: What is your name?\nAI: Hi there! My name is ASH. I'm the AI assistant at Telus Spark Science Center. It's nice to meet you!\nHuman: Tell me something interesting\nAI: Did you know the first X-ray machine was developed by Wilhelm Conrad Röentgen in 1895?\nHuman: What is the distance between the earth and the moon?\n The average distance between the Earth and the Moon is about 238,855 miles (384,400 km).",
    speechRecognition: null,
    speechStatus: null,
    onResult: null,
    openAI: null,
    speakStatus: false,
  },
  getters: {
    getOpenAI: state => state.openAI,
    getResultStatus: state => state.onResult,
    getSpeakStatus: state => state.speakStatus
  },
  mutations: {
    addQuestion(state, query) {
      state.prompt += "\nHuman: " + query;
      // console.log(state.prompt)
    },
    addAnswer(state, answer) {
      state.prompt += "\nAI: " + answer
    },
    setSpeakStatus(state, status) {
      state.speakStatus = status;
    },
    setOpenAiText(state, text){
      state.openAI = text;
    }
  },
  actions: {
    getResponse({ state }) {

      return new Promise((res) => {
        openai.createCompletion("text-davinci-003", {
          prompt: state.prompt,
          temperature: 0.9,
          max_tokens: 50,
          top_p: 1,
          frequency_penalty: 0,
          presence_penalty: 0.6,
          stop: [" Human:", " AI:"],
        }).
          then(response => {
            console.log("RESPONSE:", response)
            res(response);
          })


      })
    },
    setupSpeechRecognition({ state, dispatch }) {
      const speechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      state.speechRecognition = new speechRecognition();
      state.speechRecognition.lang = "en-US";
      state.speechRecognition.continous = true;
      state.speechRecognition.interimResults = true;
      state.openAI = "Hi! my name is ASH, you can ask me anything."
      dispatch("processSpeechRecogniton");
    },
    processSpeechRecogniton({ state, commit, dispatch }) {
      state.speechRecognition.onstart = () => {
        state.onResult = false;
        console.log("Speech Recognition Started");
        state.speechStatus = "I am listening...";
      };
      state.speechRecognition.onend = () => {
        console.log("Speech Recognition Ended");
        if (!state.onResult) {
          state.speechStatus = "Tap To Talk";
          state.onResult = false;
        }
      };

      state.speechRecognition.onresult = (event) => {
        state.onResult = true;
        let spResult = event.results[event.resultIndex];
        let spTranscript =
          event.results[event.results.length - 1][0].transcript;
        state.openAI = spTranscript;

        if (spResult.isFinal) {
          console.log(
            "Transcript word Length:",
            spTranscript.split(" ").length
          );
          if (spTranscript.split(" ").length <= 15) {
            console.log("QUERY:", spTranscript);
            state.openAI = "Thinking!";
            commit("addQuestion", spTranscript);
            dispatch("getResponse").then((response) => {
              state.openAI = response.data.choices[0];
              let result = state.openAI.text.split(":");
              if (result.length > 0) {

                state.openAI = result[1];
                commit("addAnswer", state.openAI);
                console.log(state.openAI)
                commit('setSpeakStatus', true);
                // this.speak(state.openAI);
              } else {
                commit('setSpeakStatus', true);
                state.openAI = "I dont know how to respond to this question!";
              }
              // console.log(this.openAI);
            });
            // this.$store.dispatch("requestQnA", spTranscript);
            // this.$store.commit("setSpeechTranscript", spTranscript);
            // this.$store.commit("setTextBubbleText", spTranscript);
          } else {
            state.speechStatus = "Tap To Talk";
            state.onResult = false;
          }
        }
      };


    },
    startSpeechRecognition({ state }) {
      try {
        state.speechRecognition.stop();
        state.speechRecognition.start();
        // this.processSpeechRecogniton();
      } catch (error) {
        console.log(error.message);
      }
    },
    stopSpeechRecognition({ state }) {
      try {
        state.speechRecognition.stop();
      } catch (error) {
        console.log(error.message);
      }
    },
    // speak({state}, message) {
    //   var msg = new SpeechSynthesisUtterance(message);
    //   var voices = window.speechSynthesis.getVoices();
    //   msg.voice = voices[0];
    //   msg.rate = 0.9;
    //   window.speechSynthesis.speak(msg);
    //   this.speechStatus = "Tap To Talk";
    // },


  },
  modules: {
  }
})
