<template>
  <!-- <div class="bgImg pa-0 ma-0">     -->
  <v-row
    no-gutters
    class="pa-0 ma-0 bgImg"
    id="avatar-wrapper"
    align="center"
    justify="center"
  >
    <v-col align="center" justify="center" id="divVHSS"> </v-col>
    <div id="avatar-accessories">
      <div
        style="width: 1000px; margin-left: 100px; margin-bottom: -70px"
        v-if="width > 600"
      >
        <v-card
          style="
            border-radius: 30px !important;
            background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%); opacity:0.9;
          "
          class="pa-5 d-flex justify-center align-center"
          height="240"
        >
          <v-row justify="center" align="center">
            <v-col cols="10">
              <span class="text-h4">{{ getOpenAI }} </span>
            </v-col>
            <v-col cols="2" justify="center" align="center">
              <v-icon
                @click="$store.dispatch('startSpeechRecognition')"
                size="65"
                >mdi-microphone</v-icon
              >
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
  </v-row>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "avatarDemo",
  //   props: {
  //     initialWidth: {
  //       type: Number,
  //       required: true,
  //     },
  //   },
  data() {
    return {
      width: 1080,
      openAI: "Hi! my name is ASH, you can ask me anything.",
      speechRecognition: null,
      onResult: false,
      speechStatus: "Tap To Talk",
    };
  },
  computed: {
    ...mapGetters(["getOpenAI", "getResultStatus", "getSpeakStatus"]),

    height: {
      get() {
        // if (this.$route.path === "/" || this.$route.path === "/loadData") {
        //   // return this.width * 1.125 - 180
        //   return this.width - 65;
        // }
        return this.width - 1;
      },
      set(newVal) {
        this.width = newVal;
      },
    },
  },
  watch: {
    width: function () {
      if (this.$route.path === "/") {
        window.dynamicResize(this.width, this.height);
      } else {
        window.dynamicResize(this.width, this.height);
      }
    },
    getSpeakStatus(n) {
      if (n) {
        console.log("GET STATUS:::", )
        this.speakAvatar(this.getOpenAI);
      }
    },
  },
  methods: {
    stop(){
        this.$store.commit("stopSpeechRecognition");
        window.stopSpeech();       
        this.$store.commit("setOpenAiText", '') 
    },
    speakAvatar(speakText) {
      console.log("clicked");
      //   if (this.getIsSpeaking) window.stopSpeech();
      //   if (this.getCurrentLanguage === "en") {
      window.sayText(
        '<prosody rate="+20%">' + speakText + "</prosody>",
        3,
        1,
        3
      );
      //   } else {
      //     window.sayText(speakText, this.spVoice, this.spLang, this.spEngine);
      //   }
    },
    forceResize() {
      window.dynamicResize(this.width, this.height);
    },
    loadAvatar() {
      var sc = document.createElement("script");
      sc.type = "text/javascript";
      sc.src =
        "//vhss-d.oddcast.com/vhost_embed_functions_v4.php?acc=7295865&js=1";
      sc.onload = () => {
        this.callEmebedFunction();
      };
      document.head.appendChild(sc);
    },
    callEmebedFunction() {
      setTimeout(() => {
        if (typeof AC_VHost_Embed == "function") {
          var script = document.createElement("script");
          script.type = "text/javascript";
          script.innerHTML =
            "AC_VHost_Embed(7295865,960,960,'',1,0,2712925,0,1,1,'FdNVehuFsGFPKNZoEJqtZ6DvsjLQvRVH',0, '400|300|15|15|L|T|false|0|0|0|0|0|C|0|0|0|992')";
          document.getElementById("divVHSS").appendChild(script);
        }
      }, 0);
    },
  },
  mounted() {
    this.loadAvatar();

    (window.vh_sceneLoaded = () => {
      this.forceResize();
      //   this.$store.commit('setAvatarLoaded', true);
    }),
      (window.vh_audioStarted = () => {
        //   this.$store.commit('setIsSpeaking', true);
      }),
      (window.vh_talkEnded = () => {
        this.$store.commit("setSpeakStatus", false)
        // clientApp.avatar.isTalking = false;
        // if (clientApp.avatar.promise) {
        //   clientApp.avatar.promise.resolve({interrupted : false});
        //   clientApp.avatar.promise = null;
        // }
        // Has to removed once all languages are added
        //this.$store.commit('setCurrentLanguage', 'en-US');
        //   this.$store.commit('setIsSpeaking', false);
        //   if(!this.getDefaultBubbleText){
        //     this.$store.commit('setTextBubbleText', this.$i18n.t('bubbletextafteraction'))
        //   }
      });

    this.$store.dispatch("setupSpeechRecognition");
  },
};
</script>

<style>
#divVHSS {
  width: 100%;
  z-index: 0 !important;
}
#divVHSS-inner {
  /* left: 50px !important; */
}
.character,
.vhss_main_container,
._html5Player {
  /* height: 1085px !important; */
  margin-top: -10px !important;
  z-index: -1 !important;
  position: initial !important;
}
#avatar-accessories {
  z-index: 1;
  height: 82%;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: absolute;
}
.bgImg {
  background: url("../assets/spark.jpg") no-repeat center center fixed !important;
  background-size: cover;
}
</style>