<template>
  <v-app>
    

    <v-main>
      <v-btn @click="get()"> GET</v-btn>
      <router-view ref="viewPort" class=""/>
      
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
  methods:{
    get(){
      this.$refs.viewPort.width = "50%"
      this.$refs.viewPort.height = 500
      
      console.log(`Width: ${this.$refs.viewPort.width} :: Height: ${this.$refs.viewPort.height}`)
    }

  },
  mounted(){
    
  }
};
</script>

<style>
html,
body {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

/* iframe {
  transform: scale(0.5);
  height: 1080px;
  width: 1920px;
} */

.spaceWrap {
  width: 1000px;
  height: 600px;
  padding: 0;
  overflow: hidden;
  /* background-color: red; */
}

.viewPort {
  width: 1920px;
  height: 1080px;
}

.viewPort {
  -webkit-transform: scale(0.5);
  -webkit-transform-origin: 0 0;
}

</style>

