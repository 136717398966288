<template>
  <v-row class="fill-height pa-0 ma-0">
    <v-col cols="12" align-self="center" justify="center" align="center">
      <v-card @click="startSpeechRecognition" width="200" class="pa-10" shaped>
        <v-img src="@/assets/openai.svg" contain width="100"></v-img>
      </v-card>
      <div class="mt-3 grey--text">{{ speechStatus }}</div>
    </v-col>
    <v-col cols="12" justify="center" align="center">
      <v-card width="800" outlined>
        <v-card-title>{{ openAI }}</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      openAI: "Hi! my name is ASH, you can ask me anything.",
      speechRecognition: null,
      onResult: false,
      speechStatus: "Tap To Talk",
    };
  },

  methods: {
    setupSpeechRecognition() {
      const speechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;
      // const SpeechGrammarList =
      //   window.SpeechGrammarList || window.webkitSpeechGrammarList;
      // if (!speechRecognition) console.log("Speech Recognition not supported");
      // //has to be changed court wise
      // var nouns = [
      //   "Clara",
      //   "Judge",
      //   "Lamar",
      //   "Biedscheid",
      //   "Mathew",
      //   "Sanchez",
      //   "Gagne",
      //   "Broderick",
      //   "Bulman",
      //   "Judge Wilson",
      //   "Lidyard",
      //   "Glenn",
      //   "Ellington",
      //   "Marlowe",
      //   "Sommer",
      //   "Hearing Room",
      //   "Jury",
      //   "Clerk",
      //   "Domestic",
      //   "Violence",
      //   "Child Support",
      // ];
      // var grammar =
      //   "#JSGF V1.0; grammar nouns; public <nouns> = " +
      //   nouns.join(" | ") +
      //   " ;∂";

      this.speak("Hi! my name is ASH, you can ask me anything.");

      this.speechRecognition = new speechRecognition();
      // const speechRecognitionList = new SpeechGrammarList();
      // speechRecognitionList.addFromString(grammar, 1);
      // this.speechRecognition.grammars = speechRecognitionList;
      this.speechRecognition.lang = "en-US";
      this.speechRecognition.continous = true;
      this.speechRecognition.interimResults = true;
      this.processSpeechRecogniton();
      //   this.startSpeechRecognition(); // remove this once in actual code.
    },
    processSpeechRecogniton() {
      this.speechRecognition.onstart = () => {
        this.onResult = false;
        console.log("Speech Recognition Started");
        this.speechStatus = "I am listening...";
      };
      this.speechRecognition.onend = () => {
        console.log("Speech Recognition Ended");
        if (!this.onResult) {
          this.speechStatus = "Tap To Talk";
          this.onResult = false;
        }
      };

      this.speechRecognition.onresult = (event) => {
        this.onResult = true;
        let spResult = event.results[event.resultIndex];
        let spTranscript =
          event.results[event.results.length - 1][0].transcript;
        this.openAI = spTranscript;

        if (spResult.isFinal) {
          console.log(
            "Transcript word Length:",
            spTranscript.split(" ").length
          );
          if (spTranscript.split(" ").length <= 15) {
            // console.log("QUERY:", spTranscript);
            this.speechStatus = "Thinking!";
            this.$store.commit("addQuestion", spTranscript);
            this.$store.dispatch("getResponse").then((response) => {
              this.openAI = response.data.choices[0];
              let result = this.openAI.text.split(":");
              if (result.length > 0) {
                this.openAI = result[1];
                this.$store.commit("addAnswer", this.openAI);
                this.speak(this.openAI);
              } else {
                this.openAI = "I dont know how to respond to this question!";
              }
              // console.log(this.openAI);
            });
            // this.$store.dispatch("requestQnA", spTranscript);
            // this.$store.commit("setSpeechTranscript", spTranscript);
            // this.$store.commit("setTextBubbleText", spTranscript);
          } else {
            this.speechStatus = "Tap To Talk";
            this.onResult = false;
          }
        }
      };
    },
    startSpeechRecognition() {
      try {
        this.speechRecognition.stop();
        this.speechRecognition.start();
        // this.processSpeechRecogniton();
      } catch (error) {
        console.log(error.message);
      }
    },
    stopSpeechRecognition() {
      try {
        this.speechRecognition.stop();
      } catch (error) {
        console.log(error.message);
      }
    },
    speak(message) {
      var msg = new SpeechSynthesisUtterance(message);
      var voices = window.speechSynthesis.getVoices();
      msg.voice = voices[0];
      msg.rate = 0.9;
      window.speechSynthesis.speak(msg);
      this.speechStatus = "Tap To Talk";
    },
  },
  mounted() {
    this.setupSpeechRecognition();
  },
};
</script>
